import { useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, protectedResources } from "../authConfig";
import { useSearchParams } from "react-router-dom";
import { DiscoveryDisplayNotification } from "../components/DiscoveryDisplayNotification";
import { DiscoveryApproveButton } from "../components/DiscoveryApproveButton";
import { appInsights } from '../ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { DiscoveryDisplayApproval } from "../components/DiscoveryDisplayApproval";
import { OverlaySpinner } from "../components/OverlaySpinner";

/**
 * 承認画面を表示するためのコンポーネント
 * @returns 
 */
export const ApprovalContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tokenResponse, setTokenResponse] = useState(null);
    const [searchParams] = useSearchParams();
    const qParam = searchParams.get('q');
    const pParam = searchParams.get('p');
    const rParam = searchParams.get('r');
    // 承認者の判定状態を管理するstate
    const [isApprover, setIsApprover] = useState(false);
    // 承認の状態を管理するstate
    const [approval, setApproval] = useState(null);
    // ローディングの状態を管理するstate
    const [isLoading, setIsLoading] = useState(false);
    // 承認応答期限の有効／無効を管理するstate
    const [isValid, setIsValid] = useState(false);
    // 通知に関する定数
    const [severity, setSeverity] = useState(null);
    const [message, setMessage] = useState(null);
    const componentName = "ApprovalContent";
    const [countdown, setCountdown] = useState(10);

    const traceProperties = {
        domain: window.location.hostname,
        account: {
            id: (account.localAccountId) ? account.localAccountId : null,
            name: (account.name) ? account.name : null
        },
        parameter: {
            locationId: qParam,
            requestInvocationId: rParam,
            requesterId: pParam
        }
    }

    const trackTrace = (message, level) => {
        appInsights.trackTrace({
            message: message,
            severityLevel: level,
            properties: traceProperties
        });
    }

    trackTrace(`[${componentName}]: Component has been invoked.`, SeverityLevel.Verbose);

    useEffect(() => {
        trackTrace(`[${componentName}][useEffect]: hook has been invoked.`, SeverityLevel.Verbose);
        if (account && inProgress === "none" && !tokenResponse) {
            instance.acquireTokenSilent({
                scopes: protectedResources.approvalApi.scopes,
                account: account
            }).then(async (res) => {
                const newTokenResponse = res;
                setTokenResponse(newTokenResponse);

                // （初回）承認要求データを取得する
                await callGetApproval(newTokenResponse);

                // タイマーをセットする
                const timer = setInterval(async () => {
                    // 承認要求データを取得する
                    await callGetApproval(newTokenResponse)
                        .then(() => {
                            setCountdown(10); // カウントダウンをリセット
                        })
                }, 10000); // 10秒おきに実行する

                // カウントダウン用のタイマーをセットする
                const countdownTimer = setInterval(() => {
                    setCountdown((prevCountdown) => prevCountdown - 1);
                }, 1000);

                // クリーンアップ関数を返す
                return () => {
                    clearInterval(timer); // タイマーを解除する
                    clearInterval(countdownTimer); // カウントダウンタイマーを解除する
                };
            }).catch((e) => {
                trackTrace(`[${componentName}][useEffect]: Silent token request failed. error[${JSON.stringify(e)}]`, SeverityLevel.Error);
                if (e instanceof InteractionRequiredAuthError) {
                    trackTrace(`[${componentName}][useEffect]: A server error requiring an interactive call has occurred and a redirect is used to retrieve the token.`, SeverityLevel.Information);
                    instance.acquireTokenRedirect({
                        scopes: protectedResources.approvalApi.scopes,
                        account: account
                    }).then(() => {
                        trackTrace(`[${componentName}][useEffect]: The acquireTokenRedirect method succeeded.`, SeverityLevel.Verbose);
                    }).catch((e) => {
                        trackTrace(`[${componentName}][useEffect]: The acquireTokenRedirect method failed. error[${JSON.stringify(e)}]`, SeverityLevel.Error);
                    });
                }
            });
        }
    }, [account, inProgress, instance, countdown]);

    /**
     * APIからデータを取得する非同期関数を呼び出す
     */
    const callGetApproval = async (tokenResponse) => {
        const functionName = "callGetApproval";
        trackTrace(`[${componentName}][${functionName}]: function has been invoked.`, SeverityLevel.Verbose);
        // ローディングの開始
        setIsLoading(true);
        await getApproval(tokenResponse.accessToken)
            .then((res) => {
                const newApproval = res;
                setApproval(newApproval);

                // 承認者かどうかのチェック
                const newIsApprover = checkIsApprover(newApproval, tokenResponse);
                setIsApprover(newIsApprover);
                // 要求の有効／無効チェック
                const newIsValid = checkIsValid(newApproval);
                setIsValid(newIsValid);

                // 通知メッセージの更新
                const newNotification = getNotification(newIsApprover, newIsValid, newApproval.status);
                setSeverity(newNotification.severity);
                setMessage(newNotification.message);

                // 要求が無効の場合、タイマーを解除する。
                if (!newIsValid) {
                    clearInterval(timer); // タイマーを解除する
                }
            })
            .catch((e) => {
                trackTrace(e, SeverityLevel.Error);
            });
        // ローディングの終了
        setIsLoading(false);
    };

    /**
     * 承認要求リソースを取得します。
     */
    const getApproval = (accessToken) => {
        const functionName = "getApproval";
        trackTrace(`[${componentName}][${functionName}]: function has been invoked.`, SeverityLevel.Verbose);
        return new Promise(async (resolve, reject) => {
            // API実行の準備
            const apiEndpoint = `${protectedResources.locationApi.endpoint}/${qParam}/approvals/${pParam}/requests/${rParam}`
            const custom_authorization_header = process.env["REACT_APP_AAD_APP_CUSTOM_AUTHORIZATION_HEADER"];
            const reqHeaders = new Headers();
            reqHeaders.append('Content-Type', 'application/json');
            reqHeaders.append(custom_authorization_header, 'Bearer ' + accessToken);
            const options = {
                method: 'GET',
                headers: reqHeaders,
            };

            // API実行
            await fetch(apiEndpoint, options)
                .then((res) => res.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };

    /**
     * ユーザーが承認者かどうかを判定します。
     */
    const checkIsApprover = (approval, tokenResponse) => {
        const functionName = "checkIsApprover";
        trackTrace(`[${componentName}][${functionName}]: function has been invoked.`, SeverityLevel.Verbose);
        trackTrace(`[${componentName}][${functionName}]: username[ ${tokenResponse.account.username} ], approvers[ ${JSON.stringify(approval.approvers)} ]`, SeverityLevel.Information);

        // tokenResponse から自分の upn を取得
        let myUpn = tokenResponse.account.username;

        // approvers 配列をループ
        let isApprover = false;
        for (let approver of approval.approvers) {
            // upn を比較
            if (approver.upn === myUpn) {
                // 一致するものがあれば、自分は承認者である
                isApprover = true;
                break;
            }
        }
        trackTrace(`[${componentName}][${functionName}]: isApprover[ ${isApprover} ]`, SeverityLevel.Information);
        return (isApprover);
    };

    /**
     * 承認要求が有効かどうかを判定します。
     * - 現在時刻と承認要求の応答期限を比較して、以下の要領で判定します。
     *     応答期限の方が大きい場合は、期限内のため「有効」
     *     現在時刻の方が大きい場合は、期限切れのため「無効」
     * @param {*} approval 
     * @returns 
     */
    const checkIsValid = (approval) => {
        const functionName = "checkIsValid";
        trackTrace(`[${componentName}][${functionName}]: function has been invoked.`, SeverityLevel.Verbose);
        trackTrace(`[${componentName}][${functionName}]: approvalDeadline[ ${approval.approvalDeadline} ]`, SeverityLevel.Information);
        const now = new Date();
        const deadLine = new Date(approval.approvalDeadline);
        return (now <= deadLine
            ? true
            : false);
    };

    /**
     * ボタン押下時の処理
     * @param {*} type 処理タイプ（承認/拒否）
     */
    const callApi = async (type) => {
        const functionName = "callApi";
        trackTrace(`[${componentName}][${functionName}]: function has been invoked.`, SeverityLevel.Verbose);
        const apiEndpoint = `${protectedResources.approvalApi.endpoint}/${qParam}`
        const custom_authorization_header = process.env["REACT_APP_AAD_APP_CUSTOM_AUTHORIZATION_HEADER"];
        const reqHeaders = new Headers();
        reqHeaders.append('Content-Type', 'application/json');
        reqHeaders.append(custom_authorization_header, 'Bearer ' + tokenResponse.accessToken);
        const putData = {
            actionType: type,
            locationId: qParam,
            requestInvocationId: rParam,
            requesterId: pParam
        };
        const options = {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify(putData)
        };

        // ローディングの開始
        setIsLoading(true);
        await fetch(apiEndpoint, options)
            .then((res) => {
                return res.json();
            })
            .then(async () => {
                // 承認要求リソース取得
                await getApproval(tokenResponse.accessToken)
                    .then((res) => {
                        console.log(res);
                        const newApproval = res;
                        setApproval(newApproval);
                        // 承認者かどうかのチェック
                        const newIsApprover = checkIsApprover(newApproval, tokenResponse);
                        setIsApprover(newIsApprover);

                        // 要求の有効／無効チェック
                        const newIsValid = checkIsValid(newApproval);
                        setIsValid(newIsValid);

                        // 通知メッセージの更新
                        const newNotification = getNotification(newIsApprover, newIsValid, newApproval.status);
                        setSeverity(newNotification.severity);
                        setMessage(newNotification.message);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            })
            .catch((e) => {
                console.error(e);
            });
        // ローディングの終了
        setIsLoading(false);
    };

    /**
     * 承認状態に伴う通知メッセージを構成します。
     * @param {boolean} isApprover - 承認者フラグ
     * @param {boolean} isValid - 有効フラグ
     * @param {string} status - 承認ステータス
     * @returns {object} notification
     */
    const getNotification = (isApprover, isValid, status) => {
        const functionName = "getNotification";
        trackTrace(`[${componentName}][${functionName}]: function has been invoked.`, SeverityLevel.Verbose);
        trackTrace(`[${componentName}][${functionName}]: isApprover[ ${isApprover} ], isValid[ ${isValid} ], status[ ${status} ]`, SeverityLevel.Information);
        let notification = {
            severity: null,
            message: null
        };

        if (isValid) {
            switch (status) {
                case "Pending":
                    notification.severity = (isApprover ? "warning" : "info");
                    notification.message = (isApprover ? "要求に応答してください。" : "要求は保留中です。");
                    break;
                case "Approved":
                    notification.severity = "success";
                    notification.message = (isApprover ? "要求を承認しました。" : "要求は承認されました。");
                    break;
                case "Rejected":
                    notification.severity = "error";
                    notification.message = (isApprover ? "要求を拒否しました。" : "要求は拒否されました。ただし、参照ボタンをクリックすることで、要求を再送することができます。");
                    break;
                default:
                    notification.severity = null;
                    notification.message = null;
            }
        } else {
            notification.severity = "error";
            notification.message = "この要求は期限切れです。";
        }
        return notification;
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className="notification-area-div">
                <DiscoveryDisplayNotification severity={severity} message={message + `\n（${countdown} 秒後に自動更新します）`} />
            </div>
            <div className="data-area-div">
                {isLoading && <OverlaySpinner />}
                <DiscoveryDisplayApproval approval={approval} isValid={isValid} />
                <DiscoveryApproveButton approval={approval} locationId={qParam} isApprover={isApprover} isValid={isValid} handleClick={callApi} />
            </div>
        </div>
    );
}


export const Approval = () => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            <ApprovalContent />
        </MsalAuthenticationTemplate>
    )
};